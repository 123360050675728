export const clothColors = [
    {
        id: 1253,
        name: 'Beige',
        codes: {
            top: '#E9CDA8',
            bottom: '#F0DDBF'
        },
        properties: {
            transparent: false,
            semiTransparent: true,
            pearlSurface: true,
            fireResistant: true,
            r: 60,
            a: 16,
            t: 24
        }
    },
    {
        id: 1268,
        name: 'Sand',
        codes: {
            top: '#F9F9F1',
            bottom: '#E7E5D8'
        },
        properties: {
            transparent: false,
            semiTransparent: true,
            pearlSurface: true,
            fireResistant: true,
            r: 60,
            a: 24,
            t: 16
        }
    },
    {
        id: 1250,
        name: 'Weiß',
        codes: {
            top: '#FFFFFF',
            bottom: '#FFFFFF'
        },
        properties: {
            transparent: false,
            semiTransparent: true,
            pearlSurface: true,
            fireResistant: true,
            r: 60,
            a: 24,
            t: 16
        }
    },
    {
        id: 1220,
        name: 'Reinweiß',
        codes: {
            top: '#FCFCFC',
            bottom: '#F7F7F7'
        },
        properties: {
            transparent: false,
            semiTransparent: true,
            pearlSurface: false,
            fireResistant: true,
            r: 46,
            a: 1,
            t: 53
        }
    },
    {
        id: 1263,
        name: 'Hellgrau',
        codes: {
            top: '#F3F3F3',
            bottom: '#D4D4D6'
        },
        properties: {
            transparent: false,
            semiTransparent: true,
            pearlSurface: true,
            fireResistant: true,
            r: 60,
            a: 20,
            t: 20
        }
    },
    {
        id: 1267,
        name: 'Dunkelgrau',
        codes: {
            top: '#91969A',
            bottom: '#6E7377'
        },
        properties: {
            transparent: false,
            semiTransparent: true,
            pearlSurface: true,
            fireResistant: true,
            r: 49,
            a: 41,
            t: 10
        }
    },
    {
        id: 1265,
        name: 'Schwarz',
        codes: {
            top: '#4E4E50',
            bottom: '#49494B'
        },
        properties: {
            transparent: false,
            semiTransparent: true,
            pearlSurface: true,
            fireResistant: true,
            r: 36,
            a: 63,
            t: 1
        }
    },
    {
        id: 1288,
        name: 'Khaki',
        codes: {
            top: '#E5E2D9',
            bottom: '#DDD8C5'
        },
        properties: {
            transparent: false,
            semiTransparent: true,
            pearlSurface: true,
            fireResistant: true,
            r: 60,
            a: 24,
            t: 16
        }
    },
    {
        id: 3501,
        name: 'Weiß Transparent',
        codes: {
            top: '#FFFFFF',
            bottom: '#F4F4F2'
        },
        properties: {
            transparent: true,
            semiTransparent: false,
            pearlSurface: true,
            fireResistant: false,
            r: 60,
            a: 6,
            t: 34
        }
    },
    {
        id: 3502,
        name: 'Grau Transparent',
        codes: {
            top: '#F4F6F5',
            bottom: '#D8DAD9'
        },
        properties: {
            transparent: true,
            semiTransparent: false,
            pearlSurface: true,
            fireResistant: false,
            r: 58,
            a: 19,
            t: 23
        }
    }
]

export const profileColors = [
    {
        id: 'RAL 7016',
        code: '#162628'
    },
    {
        id: 'RAL 9006',
        code: '#99989E'
    },
    {
        id: 'RAL 9007',
        code: '#6F7072'
    },
    {
        id: '',
        code: ''
    },
    {
        id: 'RAL 9010',
        code: '#FEFEF3'
    },
    {
        id: 'RAL 9016',
        code: '#F7FBFF'
    },
    {
        id: 'DB 703',
        code: '#393A3D'
    }
]
