import React, { useEffect, useState } from 'react';

import { faFilePdf } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Spinner } from '@sl-it-dev/sl-react';

import pidClient from '../../api/pidClient';

import styles from './productDocuments.module.scss';

const ProductDocuments = ({ pid }) => {
  const [documents, setDocuments] = useState(null);

  useEffect(() => {
    pidClient(`/product/${pid}/documents`).then((res) => {
      setDocuments(res.data.documents);
    });
  }, [pid]);

  return (
    <React.Fragment>
      {null === documents && <Spinner />}
      {null !== documents && (
        <div>
          {null !== documents.ce && (
            <div className={styles.document}>
              <a href={documents.ce}>
                <FontAwesomeIcon icon={faFilePdf} /> CE.pdf
              </a>
            </div>
          )}
          {null !== documents.explanation && (
            <div className={styles.document}>
              <a href={documents.explanation}>
                <FontAwesomeIcon icon={faFilePdf} /> Leistungserklärung.pdf
              </a>
            </div>
          )}

          {documents.techdocs.map((techdoc, index) => {
            return (
              <div key={index} className={styles.document}>
                <a
                  href={`${process.env.REACT_APP_API_URL}/documents/download?filename=${techdoc.file}&id=${techdoc.id}&afterSalesRequest=true`}
                >
                  <FontAwesomeIcon icon={faFilePdf} /> {techdoc.title}.
                  {techdoc.extension.toLowerCase()}
                </a>
              </div>
            );
          })}
        </div>
      )}
    </React.Fragment>
  );
};

export default ProductDocuments;
