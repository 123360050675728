import {useEffect, useState} from 'react';

import styles from './orderFormColor.module.scss';

import classNames from 'classnames';
import SVG from 'react-inlinesvg';

import transparentIcon from '../../assets/svg/Transparent_Icon.svg';
import semiTransparentIcon from '../../assets/svg/Semi-Transparent_Icon.svg';
import pearlSurfaceIcon from '../../assets/svg/Perlglanzbeschichtung_Icon.svg';
import fireResistantIcon from '../../assets/svg/schwer-entflammbar_Icon.svg';

const OrderFormColor = ({ id, name, codes, properties, onClick, active }) => {
    const [isActive, setIsActive] = useState(false);

    useEffect(() => {
        setIsActive(active);
    }, [active])

    return (
        <div className={classNames(styles.orderFormColorItem, {
            [styles.active]: active
        })} onClick={onClick}>
            <div className={styles.orderFormColorHover}>
                {properties.transparent && <SVG src={transparentIcon} />}
                {properties.semiTransparent && <SVG src={semiTransparentIcon} />}
                {properties.pearlSurface && <SVG src={pearlSurfaceIcon} />}
                {properties.fireResistant && <SVG src={fireResistantIcon} />}
                <div>R = {properties.r}%, A = {properties.a}%, T = {properties.t}%</div>
            </div>
            <div className={styles.orderFormColorPreview}>
                <div style={{ backgroundColor: codes.top }} />
                <div style={{ backgroundColor: codes.bottom }} />
            </div>
            <span>{`${id} ${name}`}</span>
            {isActive && <div className={styles.orderFormColorActive} />}
        </div>
    );
}

export default OrderFormColor;
