import {useEffect, useState} from 'react';

import styles from './orderFormProfileColor.module.scss';

import classNames from 'classnames';

const OrderFormProfileColor = ({ id, code, onClick, active }) => {
    const [isActive, setIsActive] = useState(false);

    useEffect(() => {
        setIsActive(active);
    }, [active])

    return (
        <div className={classNames(styles.orderFormProfileColorItem, {
            [styles.active]: active
        })} onClick={onClick}>
            <div className={styles.orderFormProfileColor} style={{ backgroundColor: code }} />
            <span>{id}</span>
            {isActive && <div className={styles.orderFormProfileColorActive} />}
        </div>
    );
}

export default OrderFormProfileColor;
