import React from 'react';

import styles from './orderPositions.module.scss';

import { Button } from '@sl-it-dev/sl-react';

const OrderPositions = ({ pid, positions }) => {
    return (
        <div className={styles.positions}>
            {positions.map((position, index) => {
                return (
                    <Button
                        key={index}
                        disabled={pid === position.pid}
                        onClick={() => {
                            const url = new URL(window.location);
                            url.searchParams.set('pid', position.pid);

                            window.location = url;
                        }}
                    >
                        {position.title}
                    </Button>
                );
            })}
        </div>
    );
}

export default OrderPositions;
