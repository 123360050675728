import React, {useEffect, useState} from 'react';

import Tiff from 'tiff.js';

import pidClient from '../../api/pidClient';

import { Spinner } from '@sl-it-dev/sl-react';

import styles from './productCanvas.module.scss';

const ProductCanvas = ({ pid }) => {
    const [image, setImage] = useState(null);

    useEffect(() => {
        pidClient(`/product/${pid}/drawing`, {
            responseType: 'arraybuffer'
        })
            .then((res) => {
                const contentType = res.headers['content-type'];
                if (contentType === 'image/tiff') {
                    const tiff = new Tiff({buffer: res.data})
                    document.getElementById('canvas').innerHTML = '';
                    document.getElementById('canvas').append(tiff.toCanvas());
                }

                if (contentType === 'image/png') {
                    setImage(URL.createObjectURL(new Blob([res.data], { type: contentType })));
                }
            })
        ;
    }, [pid])

    return (
        <div>
            {null === image && <div id="canvas" className={styles.drawing}><Spinner/></div>}
            {null !== image && <img style={{ maxWidth: '100%' }} src={image} alt='' />}
        </div>
    );
}

export default ProductCanvas;
