import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Button, Checkbox, Input, Select, Text } from '@sl-it-dev/sl-react';

import styles from '../bonus-form/bonusForm.module.scss';

import { faExclamationTriangle, faSpinnerThird } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import pidClient from '../../api/pidClient';

const validateEmail = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

const OrderForm = ({
  color,
  profileColor,
  separation,
  height,
  secondaryHeight,
  orderOptionalStick,
  optionalStickAmount,
  dealer,
  orderNumber,
  debitorNumber,
  pid,
  position,
}) => {
  const history = useHistory();

  const [touchedSalutation, setTouchedSalutation] = useState(false);
  const [salutation, setSalutation] = useState('');

  const [touchedFirstname, setTouchedFirstname] = useState(false);
  const [firstname, setFirstname] = useState('');

  const [touchedLastname, setTouchedLastname] = useState(false);
  const [lastname, setLastname] = useState('');

  const [touchedStreet, setTouchedStreet] = useState(false);
  const [street, setStreet] = useState('');

  const [touchedZip, setTouchedZip] = useState(false);
  const [zip, setZip] = useState('');

  const [touchedCity, setTouchedCity] = useState(false);
  const [city, setCity] = useState('');

  const [touchedEmail, setTouchedEmail] = useState(false);
  const [email, setEmail] = useState('');

  const [phone, setPhone] = useState('');
  const [newsletter, setNewsletter] = useState(false);

  const [errors, setErrors] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const [showErrors, setShowErrors] = useState(false);

  const [loading, setLoading] = useState(false);

  const addError = (field) => {
    setErrors((errors) => [...errors, field]);
  };

  const removeError = (field) => {
    setErrors((errors) => errors.filter((errorField) => errorField !== field));
  };

  useEffect(() => {
    if (!touchedSalutation) {
      setTouchedSalutation(true);

      return;
    }

    if ('' === salutation) {
      addError('salutation');
    } else {
      removeError('salutation');
    }
  }, [salutation]);
  useEffect(() => {
    if (!touchedFirstname) {
      setTouchedFirstname(true);

      return;
    }

    if ('' === firstname) {
      addError('firstname');
    } else {
      removeError('firstname');
    }
  }, [firstname]);
  useEffect(() => {
    if (!touchedLastname) {
      setTouchedLastname(true);

      return;
    }

    if ('' === lastname) {
      addError('lastname');
    } else {
      removeError('lastname');
    }
  }, [lastname]);
  useEffect(() => {
    if (!touchedStreet) {
      setTouchedStreet(true);

      return;
    }

    if ('' === street) {
      addError('street');
    } else {
      removeError('street');
    }
  }, [street]);
  useEffect(() => {
    if (!touchedZip) {
      setTouchedZip(true);

      return;
    }

    if (3 >= zip.length) {
      addError('zip');
    } else {
      removeError('zip');
    }
  }, [zip]);
  useEffect(() => {
    if (!touchedCity) {
      setTouchedCity(true);

      return;
    }

    if ('' === city) {
      addError('city');
    } else {
      removeError('city');
    }
  }, [city]);
  useEffect(() => {
    if (!touchedEmail) {
      setTouchedEmail(true);

      return;
    }

    if ('' === email || !validateEmail(email)) {
      addError('email');
    } else {
      removeError('email');
    }
  }, [email]);

  const onSubmit = (e) => {
    e.preventDefault();

    setShowErrors(true);
    setSubmitting(true);
    if (
      '' !== salutation &&
      '' !== firstname &&
      '' !== lastname &&
      '' !== street &&
      '' !== zip &&
      '' !== city &&
      '' !== email &&
      validateEmail(email) &&
      null !== color &&
      null !== profileColor &&
      null !== separation &&
      (true === ('double' === separation && '' !== height && '' !== secondaryHeight) ||
        'single' === separation)
    ) {
      setShowErrors(false);

      const path = '/cd/m8mwnhleeyawbqvrzuca';
      const formData = {
        email,
        postalAddress: {
          salutation,
          firstname,
          lastname,
          street,
          zip,
          city,
        },
        phone,
        dealer,
        orderId: orderNumber,
        newsletterAndLeadTransfer: {
          subscribeToNewsletter: newsletter,
          leadTransfer: false,
        },
        message: `
                    Auftragsnummer: ${orderNumber}
                    Positionsnummer: ${position}
                    PID: ${pid}
                    Händler: ${dealer}
                    Debitornummer: ${debitorNumber}
                    Stofffarbe: ${color}
                    Profilfarbe: ${profileColor}
                    Aufteilung: ${'single' === separation ? 'Einteilig' : 'Zweiteilig'}
                    Gesamthöhe Plissee (nur zweiteilig): ${'single' === separation ? '' : height}
                    Höhe B (nur zweiteilig): ${'single' === separation ? '' : secondaryHeight}
                    Optionaler Bedienstab: ${false === orderOptionalStick ? 'Nein' : 'Ja'}
                    Anzahl: ${
                      null === optionalStickAmount || '' === optionalStickAmount
                        ? '0'
                        : optionalStickAmount
                    }
                `,
      };

      pidClient
        .post(path, formData)
        .then((res) => {
          setSubmitting(false);

          history.push(`/?pid=${pid}&orderSuccess=1`);
        })
        .catch((err) => console.log(err));

      return;
    }

    '' === salutation ? addError('salutation') : removeError('salutation');
    '' === firstname ? addError('firstname') : removeError('firstname');
    '' === lastname ? addError('lastname') : removeError('lastname');
    '' === street ? addError('street') : removeError('street');
    '' === zip ? addError('zip') : removeError('zip');
    '' === city ? addError('city') : removeError('city');
    '' === email ? addError('email') : removeError('email');
    setSubmitting(false);
  };

  return (
    <React.Fragment>
      {!loading && (
        <form onSubmit={onSubmit}>
          {showErrors &&
            (null === color ||
              null === profileColor ||
              null === separation ||
              (true === (('double' === separation && '' === height) || '' === secondaryHeight) &&
                'single' !== separation &&
                null !== separation)) && (
              <div className={styles.row}>
                <div className={styles.singleColumn}>
                  <div className={styles.bonusFormError}>
                    <FontAwesomeIcon icon={faExclamationTriangle} />{' '}
                    <span>
                      Bitte vervollständigen Sie die fehlenden Angaben und versuchen es erneut.
                      <br />
                    </span>
                    <ul>
                      {null === color && <li>Bitte wählen Sie eine Stofffarbe aus</li>}
                      {null === profileColor && <li>Bitte wählen Sie eine Profilfarbe aus</li>}
                      {null === separation && (
                        <li>Bitte entscheiden Sie sich für ein ein- oder zweiteiliges Plissee</li>
                      )}
                      {true ===
                        (('double' === separation && '' === height) || '' === secondaryHeight) &&
                        'single' !== separation &&
                        null !== separation && (
                          <li>Bitte füllen Sie die Felder "Gesamthöhe Plissee" und "Höhe B" aus</li>
                        )}
                    </ul>
                  </div>
                </div>
              </div>
            )}
          <div className={styles.row}>
            <div className={styles.oneThirdColumn}>
              <Select
                id='salutation'
                required={true}
                label='Anrede'
                onChange={(value) => setSalutation(value)}
                theme='purpleInverted'
                value={salutation}
                isValid={-1 === errors.indexOf('salutation')}
                options={[
                  { value: '', label: 'Bitte wählen' },
                  { value: '102690000', label: 'Herr' },
                  { value: '102690001', label: 'Frau' },
                  { value: '102690003', label: 'Herr und Frau' },
                  { value: '102690004', label: 'Divers / keine Angabe' },
                ]}
              />
            </div>
          </div>

          <div className={styles.row}>
            <div className={styles.doubleColumn}>
              <Input
                id='firstname'
                type='text'
                required={true}
                label='Vorname'
                onChange={(value) => setFirstname(value)}
                theme='purpleInverted'
                value={firstname}
                isValid={-1 === errors.indexOf('firstname')}
              />
            </div>
            <div className={styles.doubleColumn}>
              <Input
                id='lastname'
                type='text'
                required={true}
                label='Nachname'
                onChange={(value) => setLastname(value)}
                theme='purpleInverted'
                value={lastname}
                isValid={-1 === errors.indexOf('lastname')}
              />
            </div>
          </div>

          <div className={styles.row}>
            <div className={styles.singleColumn}>
              <Input
                id='street'
                type='text'
                required={true}
                label='Straße und Hausnummer'
                onChange={(value) => setStreet(value)}
                theme='purpleInverted'
                value={street}
                isValid={-1 === errors.indexOf('street')}
              />
            </div>
          </div>

          <div className={styles.row}>
            <div className={styles.oneThirdColumn}>
              <Input
                id='postalCode'
                type='text'
                required={true}
                label='PLZ'
                onChange={(value) => setZip(value)}
                theme='purpleInverted'
                value={zip}
                isValid={-1 === errors.indexOf('zip')}
              />
            </div>
            <div className={styles.twoThirdColumn}>
              <Input
                id='city'
                type='text'
                required={true}
                label='Stadt'
                onChange={(value) => setCity(value)}
                theme='purpleInverted'
                value={city}
                isValid={-1 === errors.indexOf('city')}
              />
            </div>
          </div>

          <div className={styles.row}>
            <div className={styles.singleColumn}>
              <Input
                id='email'
                type='email'
                required={true}
                label='E-Mail'
                onChange={(value) => setEmail(value)}
                theme='purpleInverted'
                value={email}
                isValid={-1 === errors.indexOf('email')}
              />
            </div>
          </div>

          <div className={styles.row}>
            <div className={styles.singleColumn}>
              <Input
                id='phone'
                type='phone'
                required={false}
                label='Telefonnummer'
                onChange={(value) => setPhone(value)}
                theme='purpleInverted'
                value={phone}
                isValid={true}
              />
            </div>
          </div>

          <div className={styles.row}>
            <div className={styles.singleColumn}>
              <Checkbox
                id='newsletter'
                required={false}
                label='Ja, ich möchte in Zukunft weitere Informationen per E-Mail erhalten.'
                onChange={(value) => setNewsletter(value)}
                value={newsletter}
                isValid={true}
              />
            </div>
          </div>

          <div className={styles.row}>
            <div className={styles.singleColumn}>
              <Text>
                Ich habe die Datenschutzerklärung zur Kenntnis genommen und bestätige dies mit dem
                Absenden der Nachricht. Ich stimme zu, dass meine Angaben und Daten zur Beantwortung
                meiner Anfrage elektronisch erhoben und gespeichert werden. Die Einwilligung kann
                ich jederzeit für die Zukunft widerrufen.
              </Text>
            </div>
          </div>

          <div className={styles.row}>
            <div className={`${styles.singleColumn} ${styles.submitColumn}`}>
              <Button type='submit' disabled={submitting}>
                {!submitting && <React.Fragment>Abschicken</React.Fragment>}
                {submitting && <FontAwesomeIcon icon={faSpinnerThird} spin />}
              </Button>
            </div>
          </div>
        </form>
      )}
    </React.Fragment>
  );
};

export default OrderForm;
