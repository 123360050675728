import React, { useEffect, useState } from 'react';

import '@sl-it-dev/sl-react/dist/index.css';
import styles from './app.module.scss';

import pidClient from './api/pidClient';

import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import Info from './pages/Info';
import Order from './pages/Order';

const App = () => {
  const [loading, setLoading] = useState(true);
  const [pid, setPid] = useState(null);

  const [product, setProduct] = useState(null);

  useEffect(() => {
    const url = new URL(window.location);
    const pid = url.searchParams.get('pid');
    setPid(pid);
    pidClient.get(`/product/${pid}`).then((res) => {
      if (res.data.nanaOrder) {
        window.location.href = 'https://www.nanawall.com/';

        return;
      }

      setProduct(res.data.product);
      setLoading(false);
    });

    const body = document.querySelector('body');

    const observer = new MutationObserver(() => {
      if (document.querySelector('#modalWrapper')) {
        body.style.overflowY = 'hidden';
      } else {
        body.style.overflowY = '';
      }
    });

    // Observe changes in the body and its descendants
    observer.observe(body, { childList: true, subtree: true });
  }, []);

  return (
    <div className={styles.afterSales}>
      {null !== product && (
        <Router basename='/apps/serviceportal'>
          <Switch>
            <Route path='/order'>
              <Order pid={pid} product={product} loading={loading} />
            </Route>
            <Route path='/'>
              <Info pid={pid} product={product} loading={loading} />
            </Route>
          </Switch>
        </Router>
      )}
    </div>
  );
};

export default App;
