import {Box, Button, Headline, Input, Text} from '@sl-it-dev/sl-react';
import SVG from 'react-inlinesvg';

import { faArrowLeft } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import styles from '../app.module.scss';

import singleIcon from '../assets/svg/einteiliges-Plisee_Icon.svg';
import doubleIcon from '../assets/svg/zweiteiliges-Plissee_Icon.svg';

import transparentIcon from '../assets/svg/Transparent_Icon.svg';
import semiTransparentIcon from '../assets/svg/Semi-Transparent_Icon.svg';
import pearlSurfaceIcon from '../assets/svg/Perlglanzbeschichtung_Icon.svg';
import fireResistantIcon from '../assets/svg/schwer-entflammbar_Icon.svg';

import {clothColors, profileColors} from '../common/colors';
import OrderFormColor from '../components/order-form-color/OrderFormColor';
import React, {useEffect, useState} from 'react';
import OrderFormProfileColor from '../components/order-form-profile-color/OrderFormProfileColor';
import OrderForm from '../components/order-form/OrderForm';
import {NavLink} from 'react-router-dom';
import ProductCanvas from '../components/product-canvas/ProducCanvas';

const Order = ({ pid, product, loading }) => {
    const [selectedColor, setSelectedColor] = useState(null);
    const [selectedProfileColor, setSelectedProfileColor] = useState(null);
    const [selectedSeparation, setSelectedSeparation] = useState(null);
    const [height, setHeight] = useState(null);
    const [secondaryHeight, setSecondaryHeight] = useState(null);
    const [orderOptionalStick, setOrderOptionalStick] = useState(false);
    const [optionalStickAmount, setOptionalStickAmount] = useState(null);

    useEffect(() => {
        window.scrollTo({ top: 0 });
    }, []);

    return (
        <div>
            <NavLink to={`/?pid=${pid}`}>
                <Button onClick={() => {}}><FontAwesomeIcon icon={faArrowLeft} /> Zurück zur Produktübersicht</Button>
            </NavLink>
            {product.showPleatsOrderForm && <div style={{ marginTop: '1.5rem' }}>
                <div className={styles.row}>
                    <div className={styles.column50}>
                        <Box>
                            <Headline h2>Angebot für ihr Plissee anfordern</Headline>
                        </Box>
                        <div className={styles.p1}>
                            <Text>
                                Liebe Solarlux Kundin, lieber Solarlux Kunde,<br />
                                <br />
                                auf dieser Seite können Sie ein Angebot für Ihr individuelles Plissee anfordern. Wählen Sie dazu aus verschiedenen Farben für Stoffe und Profile und die gewünschte Ausführungsvariante (einteilig oder zweiteilig). Ebenso können Sie optional einen Bedienstab zur einfacheren Bedienung des Plissees auswählen. Bitte beachten Sie, dass diese Anforderungen für jedes Glaselement, also für jedes Plissee, separat ausgefüllt werden müsste.
                                <br />
                                <br />
                                Viele Grüße,<br />
                                Ihr Solarlux Team
                            </Text>
                        </div>
                    </div>
                    <div className={styles.column50}>
                        <Box>
                            <Headline h2>Ausgewählte Position</Headline>
                        </Box>

                        <div className={styles.p1}>
                            <Text>
                                Die Bestellung der Plissees ist ausschließlich für die gesamten, farblich markierten Elemente, jedoch nicht für einzelne Flügel möglich.
                            </Text>
                        </div>

                        <div className={styles.orderImage}>
                            <ProductCanvas pid={pid} />
                        </div>
                    </div>
                </div>

                <Box>
                    <Headline h2>Stoff-Farben auswählen</Headline>
                </Box>
                <div className={styles.p1}>
                    <div className={styles.materialLegend}>
                        <div className={styles.materialLegendIcon}>
                            <SVG src={transparentIcon} />
                            <span>Transparent</span>
                        </div>
                        <div className={styles.materialLegendIcon}>
                            <SVG src={semiTransparentIcon} />
                            <span>Semitransparent</span>
                        </div>
                        <div className={styles.materialLegendIcon}>
                            <SVG src={pearlSurfaceIcon} />
                            <span>Perlglanzbeschichtung</span>
                        </div>
                        <div className={styles.materialLegendIcon}>
                            <SVG src={fireResistantIcon} />
                            <span>Schwer entflammbar</span>
                        </div>
                    </div>

                    <div className={styles.materialLegendDescription}>
                        <Text>
                            R = Lichtreflektion<br />
                            A = Lichtabsorption<br />
                            T = Lichtdurchlässigkeit
                        </Text>
                    </div>

                    <div className={styles.materialColors}>
                        {clothColors.map((item, index) => <OrderFormColor
                            key={index}
                            id={item.id}
                            name={item.name}
                            codes={item.codes}
                            properties={item.properties}
                            active={selectedColor === item.id}
                            onClick={() => {
                                setSelectedColor(item.id === selectedColor ? null : item.id)
                            }}
                        />)}
                    </div>
                </div>

                <Box>
                    <Headline h2>Profil-Farben auswählen</Headline>
                </Box>
                <div className={styles.p1}>
                    <div className={styles.profileColors}>
                        {profileColors.map((item, index) => <OrderFormProfileColor
                            key={index}
                            id={item.id}
                            code={item.code}
                            active={selectedProfileColor === item.id}
                            onClick={() => {
                                setSelectedProfileColor(item.id === selectedProfileColor ? null : item.id)
                            }}
                        />)}
                    </div>
                </div>

                <Box>
                    <Headline h2>Einteilig / Zweiteilig</Headline>
                </Box>
                <div className={styles.p1}>
                    <div className={styles.row}>
                        <div className={styles.column50}>
                            <div className={styles.separation}>
                                <input className={styles.checkbox} type="checkbox" checked={'single' === selectedSeparation} onClick={() => setSelectedSeparation('single' === selectedSeparation ? null : 'single')} />
                                <div className={styles.separationImage} style={{ top: '-50px' }} onClick={() => setSelectedSeparation('single' === selectedSeparation ? null : 'single')}><SVG src={singleIcon} /></div>
                                <div className={styles.separationText}>
                                    <div className={styles.separationTextTitle}>Typ Q2 - Einteiliges Plissee</div>
                                    <p>
                                        Q2 ist ein einteiliges Plissee, es kann problemlos in beide Richtungen geöffnet und geschlossen werden. Mit diesem Typ erreichen Sie eine einheitliche Abschirmung in der gewünschten Höhe.<br /><br />
                                        <b>Breite 134 mm - 2.000 mm<br />Höhe  180 mm - 3.000 mm</b>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className={styles.column50}>
                            <div className={styles.separation}>
                                <input className={styles.checkbox} type="checkbox" checked={'double' === selectedSeparation} onClick={() => setSelectedSeparation('double' === selectedSeparation ? null : 'double')} />
                                <div className={styles.separationImage} style={{ top: '-25px' }} onClick={() => setSelectedSeparation('double' === selectedSeparation ? null : 'double')}><SVG src={doubleIcon} /></div>
                                <div className={styles.separationText}>
                                    <div className={styles.separationTextTitle}>Typ Q6 - Zweiteiliges Plissee</div>
                                    <p>
                                        Q6 ist ein zweiteiliges Plissee. Beide Elemente können nach oben und unten eingestellt werden. Mit diesem Typ können Sie den oberen oder wahlweise auch den unteren Teil der Verglasung getrennt abdecken. <br /><br />
                                        <b>Breite 451 mm - 1.200 mm<br />Höhe  1.500 mm - 3.000 mm</b>
                                    </p>

                                    <div className={styles.separationEnterInformation}>
                                        <Input type="number" theme="purpleInverted" isValid={true} required={'double' === selectedSeparation} label="Gesamthöhe Plissee" onChange={value => setHeight(value)}/>
                                        <Input type="number" theme="purpleInverted" isValid={true} required={'double' === selectedSeparation} label="Höhe B" onChange={value => setSecondaryHeight(value)}/>
                                    </div>
                                    <p>
                                        Bitte die Gesamthöhe des Plissees in "Gesamthöhe Plissee" und die Höhe des unteren Plisseeteiles in "Höhe B" in Millimeter angeben!
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Box>
                    <Headline h2>Optionaler Bedienstab</Headline>
                </Box>

                <div className={styles.p1} style={{ paddingBottom: 0, marginBottom: 0 }}>
                    <Text>
                        Optionaler Bedienstab zum Öffnen und Schließen hoher Plissees.
                    </Text>
                </div>

                <div className={`${styles.p1} ${styles.row}`} style={{ marginTop: '-15px' }}>
                    <div className={styles.control}>
                        <label className={styles.label}>
                            <input type="checkbox" className={styles.checkbox} checked={orderOptionalStick} onClick={() => setOrderOptionalStick(!orderOptionalStick)} />
                            <span>80 - 160 cm Bedienstablänge</span>
                        </label>
                    </div>
                    <div className={styles.control}>
                        <span>Anzahl</span>
                        <Input type="number" theme="purpleInverted" isValid={true} value={optionalStickAmount} onChange={value => setOptionalStickAmount(value)} />
                    </div>
                </div>

                <Box>
                    <Headline h2>Persönliche Daten</Headline>
                </Box>
                <div className={styles.p1}>
                    <div className={styles.row}>
                        <div className={styles.column50}>
                            <OrderForm
                                color={selectedColor}
                                profileColor={selectedProfileColor}
                                separation={selectedSeparation}
                                height={height}
                                secondaryHeight={secondaryHeight}
                                orderOptionalStick={orderOptionalStick}
                                optionalStickAmount={optionalStickAmount}
                                dealer={product.partner.name}
                                debitorNumber={product.partner.number}
                                orderNumber={product.orderId}
                                pid={pid}
                                position={product.positions.filter(position => position.pid === pid)[0].number}
                            />
                        </div>
                    </div>
                </div>
            </div>}
            {!product.showPleatsOrderForm &&  <div style={{ marginTop: '1.5rem' }}>
                <Box>
                    <Headline h2>Plissee Bestellung</Headline>
                </Box>
                <div className={`${styles.p1} ${styles.content}`}>
                    <Text>
                        Liebe Solarlux Kundin, lieber Solarlux Kunde,<br />
                        <br />
                        für dieses Produkt steht keine Plissee zur Verfügung.<br />
                        <br />
                        Wir wünschen Ihnen viel Freude mit Ihrem Solarlux Produkt,<br />
                        Ihr Solarlux Team
                    </Text>
                </div>
            </div>}
        </div>
    );
}

export default Order;
