import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Headline, Input, Select, Text, TextArea } from '@sl-it-dev/sl-react';
import { useEffect, useState } from 'react';

import { faSpinnerThird } from '@fortawesome/pro-regular-svg-icons';
import pidClient from '../../api/pidClient';
import { validateEmail, validatePhone } from '../bonus-form/BonusForm';
import styles from './serviceFormModal.module.scss';

const ServiceFormModal = ({ orderNumber, onClose }) => {
  const [touchedSalutation, setTouchedSalutation] = useState(false);
  const [salutation, setSalutation] = useState('');

  const [touchedFirstname, setTouchedFirstname] = useState(false);
  const [firstname, setFirstname] = useState('');

  const [touchedLastname, setTouchedLastname] = useState(false);
  const [lastname, setLastname] = useState('');

  const [touchedStreet, setTouchedStreet] = useState(false);
  const [street, setStreet] = useState('');

  const [touchedZip, setTouchedZip] = useState(false);
  const [zip, setZip] = useState('');

  const [touchedCity, setTouchedCity] = useState(false);
  const [city, setCity] = useState('');

  const [touchedEmail, setTouchedEmail] = useState(false);
  const [email, setEmail] = useState('');

  const [touchedCountry, setTouchedCountry] = useState(false);
  const [country, setCountry] = useState('');

  const [phone, setPhone] = useState('');
  const [touchedPhone, setTouchedPhone] = useState(false);

  const [ordernumber, setOrdernumber] = useState(orderNumber);

  const [product, setProduct] = useState('');
  const [touchedProduct, setTouchedProduct] = useState(false);

  const [message, setMessage] = useState('');
  const [touchedMessage, setTouchedMessage] = useState(false);

  const [errors, setErrors] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const addError = (field) => {
    setErrors((errors) => [...errors, field]);
  };

  const removeError = (field) => {
    setErrors((errors) => errors.filter((errorField) => errorField !== field));
  };

  useEffect(() => {
    if (!touchedSalutation) {
      setTouchedSalutation(true);

      return;
    }

    if ('' === salutation) {
      addError('salutation');
    } else {
      removeError('salutation');
    }
  }, [salutation]);

  useEffect(() => {
    if (!touchedFirstname) {
      setTouchedFirstname(true);

      return;
    }

    if ('' === firstname) {
      addError('firstname');
    } else {
      removeError('firstname');
    }
  }, [firstname]);

  useEffect(() => {
    if (!touchedLastname) {
      setTouchedLastname(true);

      return;
    }

    if ('' === lastname) {
      addError('lastname');
    } else {
      removeError('lastname');
    }
  }, [lastname]);

  useEffect(() => {
    if (!touchedStreet) {
      setTouchedStreet(true);

      return;
    }

    if ('' === street) {
      addError('street');
    } else {
      removeError('street');
    }
  }, [street]);

  useEffect(() => {
    if (!touchedZip) {
      setTouchedZip(true);

      return;
    }

    if (3 >= zip.length) {
      addError('zip');
    } else {
      removeError('zip');
    }
  }, [zip]);

  useEffect(() => {
    if (!touchedCity) {
      setTouchedCity(true);

      return;
    }

    if ('' === city) {
      addError('city');
    } else {
      removeError('city');
    }
  }, [city]);

  useEffect(() => {
    if (!touchedEmail) {
      setTouchedEmail(true);

      return;
    }

    if ('' === email || !validateEmail(email)) {
      addError('email');
    } else {
      removeError('email');
    }
  }, [email]);

  useEffect(() => {
    if (!touchedPhone) {
      setTouchedPhone(true);

      return;
    }

    if ('' === phone || !validatePhone(phone)) {
      addError('phone');
    } else {
      removeError('phone');
    }
  }, [phone]);

  useEffect(() => {
    if (!touchedProduct) {
      setTouchedProduct(true);

      return;
    }

    if ('' === product) {
      addError('product');
    } else {
      removeError('product');
    }
  }, [product]);

  useEffect(() => {
    if (!touchedMessage) {
      setTouchedMessage(true);

      return;
    }

    if ('' === message) {
      addError('message');
    } else {
      removeError('message');
    }
  }, [message]);

  const onSubmit = (e) => {
    e.preventDefault();

    setSubmitting(true);
    if (
      '' !== salutation &&
      '' !== firstname &&
      '' !== lastname &&
      '' !== street &&
      '' !== zip &&
      '' !== city &&
      '' !== email &&
      '' !== phone &&
      '' !== message &&
      validateEmail(email) &&
      validatePhone(phone)
    ) {
      const formData = {
        postalAddress: {
          salutation: salutation.toString(),
          firstname,
          lastname,
          street,
          zip,
          city,
        },
        phone,
        email,
        message,
        segment: '108',
        product,
        orderNumber: ordernumber,
        dealerNumber: '',
      };

      pidClient
        .post('/cd/esn4tqtuee2q0aanorqbww', formData)
        .then(() => {
          setSubmitting(false);
          setSubmitted(true);
        })
        .catch((err) => console.log(err));

      return;
    }

    '' === salutation ? addError('salutation') : removeError('salutation');
    '' === firstname ? addError('firstname') : removeError('firstname');
    '' === lastname ? addError('lastname') : removeError('lastname');
    '' === street ? addError('street') : removeError('street');
    '' === zip ? addError('zip') : removeError('zip');
    '' === city ? addError('city') : removeError('city');
    '' === email ? addError('email') : removeError('email');
    '' === phone ? addError('phone') : removeError('phone');
    '' === product ? addError('product') : removeError('product');
    '' === message ? addError('message') : removeError('message');

    setSubmitting(false);
  };

  return (
    <div id='modalWrapper' className={styles.modalWrapper}>
      <div className={styles.modalContent}>
        <div onClick={onClose} className={styles.modalClose}>
          <FontAwesomeIcon icon={faTimes} />
        </div>

        <div className={styles.row} style={{ paddingRight: '20px' }}>
          <div className={styles.singleColumn}>
            <Headline h2>
              {submitted ? 'Vielen Dank für Ihre Anfrage' : 'Neuen Servicefall anlegen'}
            </Headline>
            <br />
            {!submitted && (
              <Text>
                Wir stehen Ihnen für Ihre Serviceanfrage gerne zur Verfügung.
                <br /> Füllen Sie einfach unser Formular aus und unser Team setzt sich
                schnellstmöglich mit Ihnen in Verbindung.
              </Text>
            )}
          </div>
        </div>

        {submitted && (
          <div className={styles.row}>
            <div className={styles.singleColumn}>
              <Text>
                Wir prüfen Ihr Anliegen und setzen uns schnellstmöglich mit Ihnen in Verbindung.
              </Text>
            </div>
          </div>
        )}

        {!submitted && (
          <form onSubmit={onSubmit}>
            <div className={styles.row}>
              <div className={styles.oneThirdColumn}>
                <Select
                  id='salutation'
                  required={true}
                  label='Anrede'
                  onChange={(value) => {
                    setSalutation(value);
                  }}
                  theme='purpleInverted'
                  value={salutation}
                  isValid={-1 === errors.indexOf('salutation')}
                  options={[
                    { value: '', label: 'Bitte wählen' },
                    { value: '102690000', label: 'Herr' },
                    { value: '102690001', label: 'Frau' },
                    { value: '102690004', label: 'Divers / keine Angabe' },
                    { value: '102690002', label: 'Firma' },
                  ]}
                />
              </div>
            </div>

            <div className={styles.row}>
              <div className={styles.doubleColumn}>
                <Input
                  id='firstname'
                  type='text'
                  required={true}
                  label='Vorname'
                  onChange={(value) => setFirstname(value)}
                  theme='purpleInverted'
                  value={firstname}
                  isValid={-1 === errors.indexOf('firstname')}
                />
              </div>
              <div className={styles.doubleColumn}>
                <Input
                  id='lastname'
                  type='text'
                  required={true}
                  label='Nachname'
                  onChange={(value) => setLastname(value)}
                  theme='purpleInverted'
                  value={lastname}
                  isValid={-1 === errors.indexOf('lastname')}
                />
              </div>
            </div>

            <div className={styles.row}>
              <div className={styles.singleColumn}>
                <Input
                  id='street'
                  type='text'
                  required={true}
                  label='Straße und Hausnummer'
                  onChange={(value) => setStreet(value)}
                  theme='purpleInverted'
                  value={street}
                  isValid={-1 === errors.indexOf('street')}
                />
              </div>
            </div>

            <div className={styles.row}>
              <div className={styles.oneThirdColumn}>
                <Input
                  id='postalCode'
                  type='text'
                  required={true}
                  label='PLZ'
                  onChange={(value) => setZip(value)}
                  theme='purpleInverted'
                  value={zip}
                  isValid={-1 === errors.indexOf('zip')}
                />
              </div>
              <div className={styles.twoThirdColumn}>
                <Input
                  id='city'
                  type='text'
                  required={true}
                  label='Stadt'
                  onChange={(value) => setCity(value)}
                  theme='purpleInverted'
                  value={city}
                  isValid={-1 === errors.indexOf('city')}
                />
              </div>
            </div>

            <div className={styles.row}>
              <div className={styles.singleColumn}>
                <Input
                  id='email'
                  type='email'
                  required={true}
                  label='E-Mail'
                  onChange={(value) => setEmail(value)}
                  theme='purpleInverted'
                  value={email}
                  isValid={-1 === errors.indexOf('email')}
                />
              </div>
            </div>

            <div className={styles.row}>
              <div className={styles.singleColumn}>
                <Input
                  id='phone'
                  type='phone'
                  required={true}
                  label='Telefonnummer'
                  onChange={(value) => setPhone(value)}
                  theme='purpleInverted'
                  value={phone}
                  isValid={-1 === errors.indexOf('phone')}
                />
                <Text>Bitte geben Sie die Telefonnummer im Format +49 1234 56789 an.</Text>
              </div>
            </div>

            <div className={styles.row}>
              <div className={styles.doubleColumn}>
                <Input
                  id='orderNumber'
                  type='text'
                  required={false}
                  label='Auftragsnummer'
                  onChange={(value) => setOrdernumber(value)}
                  theme='purpleInverted'
                  value={ordernumber}
                  isValid={true}
                />
              </div>
              <div className={styles.doubleColumn}>
                <Select
                  id='product'
                  required={true}
                  label='Produkt'
                  onChange={(value) => {
                    setProduct(value);
                  }}
                  theme='purpleInverted'
                  value={product}
                  isValid={-1 === errors.indexOf('product')}
                  options={[
                    { value: '', label: 'Bitte wählen' },
                    { value: 'cero', label: 'cero' },
                    { value: 'Glas-Faltwand', label: 'Glas-Faltwand' },
                    { value: 'Horizontal-Schiebe-Wand', label: 'Horizontal-Schiebe-Wand' },
                    { value: 'Schiebe-Dreh-System', label: 'Schiebe-Dreh-System' },
                    { value: 'Schiebe-System', label: 'Schiebe-System' },
                    { value: 'Terrassendach / Glashaus', label: 'Terrassendach / Glashaus' },
                    { value: 'Wintergarten', label: 'Wintergarten' },
                    { value: 'Anderes Produkt', label: 'Anderes Produkt' },
                  ]}
                />
              </div>
            </div>

            <div className={styles.row}>
              <div className={styles.singleColumn}>
                <TextArea
                  id='message'
                  type='text'
                  required={true}
                  label='Nachricht'
                  onChange={(value) => setMessage(value)}
                  theme='purpleInverted'
                  value={message}
                  isValid={-1 === errors.indexOf('message')}
                  rows={8}
                />
                <Text>
                  Fotos und Dokumente können Sie uns zu einem späteren Zeitpunkt zur Verfügung
                  stellen.
                </Text>
              </div>
            </div>

            <div className={styles.row}>
              <div className={`${styles.singleColumn} ${styles.submitColumn}`}>
                <Button type='submit' disabled={submitting}>
                  {!submitting && <>Abschicken</>}
                  {submitting && <FontAwesomeIcon icon={faSpinnerThird} spin />}
                </Button>
              </div>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default ServiceFormModal;
